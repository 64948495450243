<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">

    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12" class="align-items-center d-flex">
          <h2 class="content-header-title float-left pr-1 mb-0 font-medium-2">
            <portal-target name="pagetitle">
              {{ $route.meta.pageTitle }}
            </portal-target>
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="py-0">
              <b-breadcrumb-item :to="user ? routeNames.DASHBOARD : '/'">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex"
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import routeNames from '@/router/route-names.enum'

export default {
  directives: {
    Ripple,
  },

  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },

  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },

  setup() {
    return {
      routeNames
    }
  }
}
</script>
