export default [
  {
    title: 'Dashboard',
    route: 'Dashboard',
    icon: 'ColumnsIcon',
    forAdmin: false
  },
  {
    header: 'Reports',
  },
  {
    title: 'New Applications',
    route: 'Applications',
    icon: 'ActivityIcon',
  },
  {
    title: 'Re-Applications',
    route: 'ReApplications',
    icon: 'ActivityIcon',
  },
  {
    title: 'Course Leaders',
    route: 'CLReport',
    icon: 'ActivityIcon',
  }
]