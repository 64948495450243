<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <dark-toggler class="d-block mr-2" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user && user.login }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/user.svg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from "@/auth/jwt/useJwt";
import {mapActions, mapMutations, mapGetters} from 'vuex';
import {UserService} from "@/services/user.service";
import EventBus from "@/utils/event-bus";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    BButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  setup(props, ctx) {
    const userService = new UserService(ctx.root.$http);
    return {
      userService
    }
  },
  data() {
    return {
      disconnectLoading: false
    }
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      await this.$router.replace({name: 'login'})
      this.setUser(null)
    },
    ...mapActions({
      getUser: 'user/loadUser'
    }),
    ...mapMutations({
      setUser: 'user/setUser'
    })
  }
}
</script>

<style lang="scss" scoped>
.menu-icon {
  font-size: 16px;
  width: 16px;
  min-width: 16px;
  text-align: center;
  font-weight: 600;
  display: block;
}

.dropdown-user::v-deep {
  .dropdown-menu {
    width: 16rem !important;
  }
}
</style>
